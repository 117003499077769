import React from "react";
import { store } from '../store';
import { getDic } from '../assets/i18n/dictionary';
import {
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,
} from "react-native";
import FormRow from './FormRow';
import FormColumn from './FormColumn';
import Alert from './Alert';
import Loading from './Loading';
import Icon from './Icon';
import Dialog from './Dialog';
import ButtonTransparent from './ButtonTransparent';
import Modal from "react-native-modal";

const SideMenu = ({
  open,
  onClose,
  navigateTo,
  navigation,
  ...props
}) => {

  const menuMinWidth = 300;
  const poweredByInteegraHeight = Platform.OS === 'ios' ? 60 : 40;
  const [dimensions, setDimensions] = React.useState({ menuWidth: 0, backdropWidth: 0, menuHeight: 0 });
  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [dialogExitAppControl, setDialogExitAppControl] = React.useState({
    open: false,
    title: '',
    message: '',
    command: '', // trySyncDataAndExit, exitApp
  });

  React.useEffect(() => {
    getComponentData();
    const subscription = Dimensions.addEventListener(
      "change",
      (newDimensions) => {
        setMenuWidth(newDimensions.window.width, newDimensions.window.height);
      }
    );

    return () => subscription?.remove();
  }, []);

  const getComponentData = React.useCallback(async () => {
    const _dimensions = Dimensions.get('window');
    setMenuWidth(_dimensions.width, _dimensions.height);
  });

  const setMenuWidth = React.useCallback((width = 0, height = 0) => {
    let _backdropWidth = width - menuMinWidth
    setDimensions({
      menuWidth: width < menuMinWidth ? width : menuMinWidth,
      backdropWidth: _backdropWidth < 0 ? 0 : _backdropWidth,
      menuHeight: height - poweredByInteegraHeight
    });
  });

  const closeMenu = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  });

  const localNavigateTo = React.useCallback((page = '') => {
    if (navigateTo) {
      navigateTo(page);
    }
  });

  const localReplaceTo = React.useCallback((page = '') => {
    if (navigateTo) {
      navigateTo(page);
    }
  });

  const trySyncDataAndExitApp = React.useCallback(async () => {
    try {
      if (dialogExitAppControl.command === 'trySyncDataAndExit') {
        setLoadingControl({ open: true, message: getDic("sincronizando") });

        // await syncDataService.syncData(true, true);

        navigation.replace('GuestRegistrationPage');
      } else {
        navigation.replace('GuestRegistrationPage');
      }
    } catch (error) {
      console.log('Error trySyncDataAndExitApp', error);
      setDialogExitAppControl({
        ...dialogExitAppControl,
        open: true,
        title: getDic("erro"),
        message: `${getDic("erro")} ${getDic("ao")} ${getDic("sincronizar")}, ${getDic("deseja-sair")}`,
        command: 'exitApp'
      });
    } finally {
      setLoadingControl({ ...loadingControl, open: false });
    }
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      margin: 0
    },
    backdrop: {
      height: '100%',
    },
    window: {
      width: '100%',
      height: '100%',
      backgroundColor: '#456091',
      elevation: 20,
      shadowColor: '#52006A',
      padding: 10,
    },
    windowButtons: {
      width: '100%',
      height: dimensions.menuHeight,
    },
    poweredByInteegra: {
      height: poweredByInteegraHeight,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontFamily: 'Verdana',
      color: 'white',
      fontSize: 17
    },
  });

  return (
    <>
      <Modal
        style={styles.container}
        animationIn={"bounceInLeft"}
        animationOut={"bounceOutLeft"}
        animationInTiming={800}
        animationOutTiming={800}
        isVisible={open}
      >
        <FormRow>
          <FormColumn width={dimensions.menuWidth}>
            <View style={styles.window}>
              <View style={styles.windowButtons}>
                <FormRow justifyContent='flex-end' paddingTop={Platform.OS === 'ios' ? 20 : null}>
                  <ButtonTransparent padding={10} onPress={() => closeMenu()}>
                    <Icon type="AntDesign" name="close" size={20} color={'white'} />
                  </ButtonTransparent>
                </FormRow>
                <ButtonTransparent padding={10} onPress={() => localReplaceTo('UserProfilePage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="Ionicons" name="person" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("perfil")}</Text>
                  </FormRow>
                </ButtonTransparent>
                <ButtonTransparent padding={10} onPress={() => localReplaceTo('HomePage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="AntDesign" name="home" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("home")}</Text>
                  </FormRow>
                </ButtonTransparent>
                <ButtonTransparent padding={10} onPress={() => localReplaceTo('ConfigPage')}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="AntDesign" name="setting" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("configuracoes")}</Text>
                  </FormRow>
                </ButtonTransparent>
                {renderAdminButtons()}
                <ButtonTransparent padding={10} onPress={() => {
                  setDialogExitAppControl({
                    ...dialogExitAppControl,
                    open: true,
                    title: '',
                    message: `${getDic("deseja-sair")}`,
                    command: 'trySyncDataAndExit'
                  });
                }}>
                  <FormRow>
                    <View style={{ paddingRight: 10 }}>
                      <Icon type="MaterialCommunityIcons" name="location-exit" size={20} color={'white'} />
                    </View>
                    <Text style={styles.text}>{getDic("sair")}</Text>
                  </FormRow>
                </ButtonTransparent>
              </View>
              <View style={styles.poweredByInteegra}>
                <Text style={styles.text}>Powered by Inteegra</Text>
              </View>
            </View>
          </FormColumn>
          <FormColumn width={dimensions.backdropWidth}>
            <TouchableWithoutFeedback
              onPress={() => closeMenu()}
              style={styles.backdrop}
            >
              <View style={styles.backdrop}></View>
            </TouchableWithoutFeedback>
          </FormColumn>
        </FormRow>

        {modalControl()}
      </Modal>
    </>
  );

  function renderAdminButtons() {
    if (store.getState().user.status !== 'admin') {
      return null;
    }
    return (
      <>
        <ButtonTransparent padding={10} onPress={() => localNavigateTo('EventRegisterPage')}>
          <FormRow>
            <View style={{ paddingRight: 10 }}>
              <Icon type="MaterialIcons" name="event" size={20} color={'white'} />
            </View>
            <Text style={styles.text}>{getDic("cadastrar")} {getDic("evento")}</Text>
          </FormRow>
        </ButtonTransparent>
      </>
    )
  }

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (dialogExitAppControl.open) {
      return (
        <Dialog
          open={dialogExitAppControl.open}
          title={dialogExitAppControl.title}
          message={dialogExitAppControl.message}
          icon='info'
          button1OnPress={() => trySyncDataAndExitApp()}
          button2OnPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
          backdropPress={() => setDialogExitAppControl({ ...dialogExitAppControl, open: false })}
        />
      )
    }

    return null;
  }
};

export default SideMenu;

import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Button,
} from '../../components';

const ButtonsList = ({
  dimensions,
  navigatePage,
}) => {

  const width = dimensions.width > 610 ? 600 : dimensions.width - 10;

  return (
    <>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Área de Negócios'}
              onPress={() => navigatePage('Stage2BusinessAreaPage')}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'BSA'}
              onPress={() => navigatePage('Stage2BSAPage')}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Key Offering'}
              onPress={() => navigatePage('Stage2KeyOfferingPage')}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Setores'}
              onPress={() => navigatePage('Stage2SectorsPage')}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
    </>
  )
};

export default ButtonsList;

const obj = {
  id: '',
  name: '',
  sex: '',
  cellPhone: '',
  email: '',
  address: '',
  emergencyPhone: '',
  shirtSize: '',
  shoeSize: '',
  superhero: '',
  airportIn: '',
  airportOut: '',
  expectationsMessage: '',
  contact: '',
  privacyPolicy: false,
}

export default function UserModel(params = obj) {
  return {
    ...obj,
    ...params,
  };
};

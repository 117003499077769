import React from 'react';
import { Dimensions, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { getDic } from './../assets/i18n/dictionary';
import Dialog from './Dialog';

const SignaturePadProp = ({
  backgroundColor = '#e8eced',
  buttonBackgroundColor = '#ffffff',
  buttonTextColor = '#000000',
  onSubmit,
  ...props
}) => {

  const injectedJavascript = `(function() {
    window.postMessage = function(data) {
    window.ReactNativeWebView.postMessage(data);
  };
  })()
  `;

  const webViewRef = React.useRef();
  const [htmlSource, setHtmlSource] = React.useState();
  const [renderWebview, setRenderWebview] = React.useState(true);
  const [dialogClearCanvasControl, setDialogClearCanvasControl] = React.useState({
    open: false,
    title: '',
    message: '',
  });

  React.useEffect(() => {
    getComponentData();

    const subscription = Dimensions.addEventListener(
      "change",
      () => {
        getComponentData();
      }
    );

    if (Platform.OS === 'web') {
      window.addEventListener("message", (e) => {
        getMessageFromWebView(e);
      })
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener("message", null);
      }
      subscription?.remove();
    }
  }, []);

  const getComponentData = React.useCallback(async () => {
    try {
      let _size = Dimensions.get('window');

      let _source = `
      <html>
    
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      
      <body style="background-color:${backgroundColor}">
        <div class="container" style="text-align:center;background-color:${backgroundColor};padding: 5px;">
          <div class="row">
            <div class="col-md-12">
              <canvas id="sig-canvas" width="${_size.width - 50}" height="${_size.height - 250}" style="background-color:white">
                Seu navegador não é compatível
              </canvas>
            </div>
          </div>
          <div style="padding:5px; display: flex; flex-direction: row; justify-content: space-between">            
            <div>
              <button 
                style="
                  background-color:${buttonBackgroundColor};
                  color:${buttonTextColor}
                  text-align: center;
                  text-decoration: none;
                  font-size: 20px;
                  border: none;
                  border-radius: 5px;
                " 
                id="sig-clearBtn"
              >${getDic("limpar")}</button>
            </div>
            <div id="sig-submitBtn" hidden style="padding:5px; background-color:${buttonBackgroundColor}; border-radius: 5px;">
              <button style="
                  background-color: green;
                  color: white;
                  text-align: center;
                  text-decoration: none;
                  font-size: 30px;
                  border: none;
                  border-radius: 5px;
                "  
              >${getDic("enviar")}</button>
            </div>
          </div>
        </div>
      </body>
      
      <script>
        (function () {
          window.requestAnimFrame = (function (callback) {
            return window.requestAnimationFrame ||
              window.webkitRequestAnimationFrame ||
              window.mozRequestAnimationFrame ||
              window.oRequestAnimationFrame ||
              window.msRequestAnimaitonFrame ||
              function (callback) {
                window.setTimeout(callback, 1000 / 60);
              };
          })();
      
          // Set up the UI
          var clearBtn = document.getElementById("sig-clearBtn");
          var submitBtn = document.getElementById("sig-submitBtn");
      
          var canvas = document.getElementById("sig-canvas");
          var ctx = canvas.getContext("2d");
          ctx.strokeStyle = "#222222";
          ctx.lineWidth = 4;
      
          var drawing = false;
          var mousePos = {
            x: 0,
            y: 0
          };
          var lastPos = mousePos;
      
          canvas.addEventListener("mousedown", function (e) {
            drawing = true;
            lastPos = getMousePos(canvas, e);
            submitBtn.hidden = false;
          }, false);
      
          canvas.addEventListener("mouseup", function (e) {
            drawing = false;
          }, false);
      
          canvas.addEventListener("mousemove", function (e) {
            mousePos = getMousePos(canvas, e);
          }, false);
      
          // Add touch event support for mobile
          canvas.addEventListener("touchstart", function (e) {
      
          }, false);
      
          canvas.addEventListener("touchmove", function (e) {
            var touch = e.touches[0];
            var me = new MouseEvent("mousemove", {
              clientX: touch.clientX,
              clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
          }, false);
      
          canvas.addEventListener("touchstart", function (e) {
            mousePos = getTouchPos(canvas, e);
            var touch = e.touches[0];
            var me = new MouseEvent("mousedown", {
              clientX: touch.clientX,
              clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
          }, false);
      
          canvas.addEventListener("touchend", function (e) {
            var me = new MouseEvent("mouseup", {});
            canvas.dispatchEvent(me);
          }, false);
      
          function getMousePos(canvasDom, mouseEvent) {
            var rect = canvasDom.getBoundingClientRect();
            return {
              x: mouseEvent.clientX - rect.left,
              y: mouseEvent.clientY - rect.top
            }
          }
      
          function getTouchPos(canvasDom, touchEvent) {
            var rect = canvasDom.getBoundingClientRect();
            return {
              x: touchEvent.touches[0].clientX - rect.left,
              y: touchEvent.touches[0].clientY - rect.top
            }
          }
      
          function renderCanvas() {
            if (drawing) {
              ctx.moveTo(lastPos.x, lastPos.y);
              ctx.lineTo(mousePos.x, mousePos.y);
              ctx.stroke();
              lastPos = mousePos;
            }
          }
      
          // Prevent scrolling when touching the canvas
          document.body.addEventListener("touchstart", function (e) {
            if (e.target == canvas) {
              e.preventDefault();
            }
          }, false);
          document.body.addEventListener("touchend", function (e) {
            if (e.target == canvas) {
              e.preventDefault();
            }
          }, false);
          document.body.addEventListener("touchmove", function (e) {
            if (e.target == canvas) {
              e.preventDefault();
            }
          }, false);
      
          (function drawLoop() {
            requestAnimFrame(drawLoop);
            renderCanvas();
          })();
      
          function clearCanvas() {
            canvas.width = canvas.width;
            submitBtn.hidden = true;
          }
      
          clearBtn.addEventListener("click", function (e) {
            ${Platform.OS === 'web' ? `
            window.top.postMessage(JSON.stringify({
              message: 'openClearCanvasConfirmation',
              data: ''
            }), '*');            
            ` : `
            window.postMessage(JSON.stringify({
              message: 'openClearCanvasConfirmation',
              data: ''
            }), '*');
            `}    
          }, false);
          submitBtn.addEventListener("click", function (e) {
            var dataUrl = canvas.toDataURL();
            ${Platform.OS === 'web' ? `
            window.top.postMessage(JSON.stringify({
              message: 'base64ToDownload',
              data: dataUrl
            }), '*');          
            ` : `
            window.postMessage(JSON.stringify({
              message: 'base64ToDownload',
              data: dataUrl
            }), '*');
            `}
          }, false);
      
          window.setTimeout(() => {
            clearCanvas();
          }, 100);
        })();
      </script>
      
      </html>  
      `;

      setHtmlSource(_source);
    } catch (error) {
      console.log('error getComponentData', error);
      setAlertControl({
        open: true,
        title: getDic("erro"),
        message: getDic("enviado-erro"),
        icon: 'error'
      });
    }
  });

  const getMessageFromWebView = React.useCallback(async (message) => {
    try {
      if (Platform.OS === 'web') {
        hendleWebMessage();
      } else {
        handleMobileMessage();
      }

    } catch (error) {
      console.log('Error getMessageFromWebView', error);
    }

    function hendleWebMessage() {
      let _data = JSON.parse(message.data);

      if (_data.message && _data.message === 'base64ToDownload') {
        let _signature = _data.data.substring(22);
        onSubmit(_signature);
      }
      if (_data.message && _data.message === 'openClearCanvasConfirmation') {
        setDialogClearCanvasControl({
          ...dialogClearCanvasControl,
          open: true,
          title: getDic("atencao"),
          message: getDic("confirmacao-acao"),
        });
      }
    }

    function handleMobileMessage() {
      let _data = JSON.parse(message.nativeEvent.data);

      if (_data.message && _data.message === 'base64ToDownload') {
        let _signature = _data.data.substring(22);
        onSubmit(_signature);
      }
      if (_data.message && _data.message === 'openClearCanvasConfirmation') {
        setDialogClearCanvasControl({
          ...dialogClearCanvasControl,
          open: true,
          title: getDic("atencao"),
          message: getDic("confirmacao-acao"),
        });
      }
    }
  });

  const clearWebview = React.useCallback(async () => {
    try {
      setRenderWebview(false);

      setTimeout(() => {
        setRenderWebview(true);
      }, 500);
    } catch (error) {
      console.log('Error clearWebview', error);
    }
  });

  if (!htmlSource) {
    return null;
  }
  if (Platform.OS === 'web') {
    return (
      <>
        {renderWebview && (
          <iframe id="codeFrame" style={{ height: '100%' }} srcDoc={htmlSource}></iframe>
        )}

        <Dialog
          open={dialogClearCanvasControl.open}
          title={dialogClearCanvasControl.title}
          message={dialogClearCanvasControl.message}
          icon='danger'
          button1OnPress={() => {
            clearWebview();
            setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false });
          }}
          button2OnPress={() => setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false })}
          backdropPress={() => setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false })}
        />
      </>
    )
  }
  return (
    <>
      {renderWebview && (
        <WebView
          ref={(ref) => webViewRef.current = ref}
          source={{ html: htmlSource }}
          style={{ flex: 1, backgroundColor: backgroundColor }}
          onMessage={getMessageFromWebView}
          injectedJavaScript={injectedJavascript}
          scrollEnabled={false}
        />
      )}

      <Dialog
        open={dialogClearCanvasControl.open}
        title={dialogClearCanvasControl.title}
        message={dialogClearCanvasControl.message}
        icon='danger'
        button1OnPress={() => {
          clearWebview();
          webViewRef.current.reload();
          setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false });
        }}
        button2OnPress={() => setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false })}
        backdropPress={() => setDialogClearCanvasControl({ ...dialogClearCanvasControl, open: false })}
      />
    </>
  )
};

export default SignaturePadProp;

import * as React from 'react';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import {
  Container,
  Loading,
  Alert,
} from '../../components';
import { Dimensions } from 'react-native';
import rankingService from '../../services/rankingService';

import ButtonsList from '../../components/PageComponents/ButtonsList';
import ModalRenderContent from '../../components/PageComponents/ModalRenderContent';

const MainStagePage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info'
  });
  const [modalContentControl, setModalContentControl] = React.useState({
    open: false,
    url: '',
    format: '',
  });
  const [pathParams, setPathParams] = React.useState({
    errorMessage: '',
    eventId: '',
    guestId: '',
  });

  const modalCotent = {
    a1: {
      rankingCode: 'PALCOPRINCIPALABERTURA',
      url: 'https://s3.us-east-1.amazonaws.com/bucket.aws.private/hall_83778/PALCO_PRINCIPAL/ABERTURA%20RICARDO%20-%20HALL%20E%20APP.mp4',
      text: 'Abertura',
      format: 'video',
    },
    // a2: {
    //   rankingCode: 'PALCOPRINCIPALEVP',
    //   url: 'https://s3.us-east-1.amazonaws.com/bucket.aws.private/hall_83778/PALCO_PRINCIPAL/EVP.mp4',
    //   text: 'EVP',
    //   format: 'video',
    // },
    // a3: {
    //   rankingCode: 'PALCOPRINCIPALPRINCIPIOSDELIDERANCA',
    //   url: 'https://s3.us-east-1.amazonaws.com/bucket.aws.private/hall_83778/PALCO_PRINCIPAL/VIDEO%20PRINCIPIOS%20DE%20LIDERAN%C3%87A%20-%20COM%20LEGENDA.mp4',
    //   text: 'Principios de Liderança',
    //   format: 'video',
    // },
    a4: {
      rankingCode: 'PALCOPRINCIPALORGANOGRAMA',
      url: 'https://s3.us-east-1.amazonaws.com/bucket.aws.private/hall_83778/PALCO_PRINCIPAL/NTT%20DATA_v16%20COM%20TRILHA.mp4',
      text: 'Organograma',
      format: 'video',
    },
    // a5: {
    //   rankingCode: 'PALCOPRINCIPALVIDEODORICARDO',
    //   url: 'https://s3.us-east-1.amazonaws.com/bucket.aws.private/hall_83778/PALCO_PRINCIPAL/DIGITAL%20LOVERS__ABERTURA%20RICARDO.mp4',
    //   text: 'Vídeo do Ricardo',
    //   format: 'video',
    // },
    // a6: {
    //   rankingCode: 'PALCOPRINCIPALQUEMEANTTDATA',
    //   url: '',
    //   text: 'Quem é a NTT Data',
    //   format: 'video',
    // },
  }

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, [])

  const getPageData = React.useCallback(async () => {
    try {
      let _params = getRouteParams();
      if (_params.errorMessage) {
        setAlertControl({
          open: true,
          title: getDic("erro"),
          message: _params.errorMessage,
          icon: 'error',
        });
      }
      setPathParams(_params);
    } catch (error) {
      console.log('Error getPageData', error);
    } finally {
      setIsLoading(false);
      setLoadingControl({ ...loadingControl, open: false });
    }

    function getRouteParams() {
      try {
        let _params = props.route.params;

        let _return = {
          errorMessage: '',
          eventId: _params?.eventId || '',
          guestId: _params?.guestId || '',
        }

        if (!_return.eventId) {
          _return.errorMessage = 'Parâmetro "eventId" não declarado';
        }
        if (!_return.guestId) {
          _return.errorMessage = 'Parâmetro "guestId" não declarado';
        }

        return _return;
      } catch (error) {
        console.log('Error getRouteParams', error);
        throw error;
      }
    }
  });

  const openModalContent = React.useCallback(async (content) => {
    try {
      setModalContentControl({
        open: true,
        url: content.url,
        format: content.format,
      });

      await rankingService.setAdminPlusRankingPoints(
        pathParams.guestId,
        pathParams.eventId,
        content.rankingCode,
      );

    } catch (error) {
      console.log('Error openModalContent', error);
    }
  });

  return (
    <Container
      background='#1680ac'
      padding={0}
      scroll
    >
      <ButtonsList
        dimensions={dimensions}
        modalCotent={modalCotent}
        openModalContent={openModalContent}
      />

      {modalControl()}
    </Container >
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }
    if (modalContentControl.open) {
      return (
        <ModalRenderContent
          dimensions={dimensions}
          modalContentControl={modalContentControl}
          setModalContentControl={setModalContentControl}
        />
      )
    }

    return null;
  }
};

export default MainStagePage;

import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Button,
} from '../../components';

const ButtonsList = ({
  dimensions,
  modalCotent,
  openModalContent,
}) => {

  const width = dimensions.width > 610 ? 600 : dimensions.width - 10;

  return (
    <FormRow justifyContent='center'>
      <FormColumn width={width}>
        {renderButtons()}
      </FormColumn>
    </FormRow>
  )

  function renderButtons() {
    const _buttons = [];

    for (var key in modalCotent) {
      if (modalCotent[key].url) {
        _buttons.push(modalCotent[key]);
      }
    }

    return (
      <>
        {_buttons.map(button => (
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={button.text}
              onPress={() => openModalContent(button)}
            />
          </FormRow>
        ))}
      </>
    )
  }

};

export default ButtonsList;

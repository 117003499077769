import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  SafeAreaView,
  Platform,
  ImageBackground,
  StatusBar,
} from 'react-native';
import Icon from './Icon';
import SideMenu from './SideMenu';
import Loading from './Loading';
import Alert from './Alert';

const Container = ({
  children,
  width = null,
  background = null,
  backgroundImage = null,
  padding = 5,
  height = null,
  minHeight = undefined,
  header,
  headerContent,
  headerSubContent,
  headerBackgroundColor = '#456091',
  headerTextColor = "white",
  headerPadding = 15,
  menu = false,
  backButton = false,
  navigation,
  scroll,
  scrollHorizontal = false,
  ...props
}) => {

  const [loadingControl, setLoadingControl] = React.useState({
    open: false,
    message: '',
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info',
  });
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const navigateTo = (page = '') => {
    if (navigation) {
      setOpenSideMenu(false);
      navigation.navigate(page);
    }
  }

  const navigateBack = React.useCallback(() => {
    setOpenSideMenu(false);
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      window.history.back();
    }
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: background || 'transparent',
      width: width,
      height: height,
      minHeight: minHeight
    },
    children: {
      padding: padding,
      flex: 1
    },
    headerContainer: {
      backgroundColor: headerBackgroundColor,
      padding: headerPadding,
      elevation: 10,
      flexDirection: 'row'
    },
    headerText: {
      color: headerTextColor,
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: 20,
      paddingLeft: menu ? 10 : undefined
    },
    menuButton: {
      backgroundColor: headerBackgroundColor,
      width: 30,
      height: 30,
      borderRadius: 30,
      opacity: isMouseOver ? 0.7 : 1,
    },
    menuButtonIcon: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
    }
  });

  return (
    <SafeAreaView
      key='container'
      style={
        styles.container
      }
    >
      <StatusBar
        animated={true}
        backgroundColor={headerBackgroundColor || '#456091'}
      />
      {(header || headerContent) && (
        <View key='header' style={styles.headerContainer}>
          {menu && (
            <TouchableOpacity
              style={
                styles.menuButton
              }
              onPress={() => setOpenSideMenu(!openSideMenu)}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
            >
              <View style={
                styles.menuButtonIcon
              }>
                <Icon type="FontAwesome5" name="bars" size={20} color={headerTextColor} />
              </View>
            </TouchableOpacity>
          )}
          {!menu && backButton && (
            <TouchableOpacity
              style={
                styles.menuButton
              }
              onPress={() => navigateBack()}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
            >
              <View style={
                styles.menuButtonIcon
              }>
                <Icon type="FontAwesome5" name="arrow-left" size={20} color={headerTextColor} />
              </View>
            </TouchableOpacity>
          )}
          {header ? (
            <Text style={styles.headerText}>{header}</Text>
          ) : headerContent ? (
            <>{headerContent}</>
          ) : null}
        </View>
      )}
      {headerSubContent}

      <ImageBackground source={{ uri: backgroundImage }} resizeMode='stretch' style={{ flex: 1 }}>
        {scroll ? (
          <KeyboardAvoidingView
            style={styles.children}
            behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={50}
          >
            {scrollHorizontal ? (
              <ScrollView>
                <ScrollView horizontal={true}>
                  {children}
                </ScrollView>
              </ScrollView>
            ) : (
              <ScrollView>
                {children}
              </ScrollView>
            )}

          </KeyboardAvoidingView>
        ) : (
          <View
            style={styles.children}
          >
            {children}
          </View>
        )}
      </ImageBackground>

      <SideMenu
        open={openSideMenu}
        onClose={() => setOpenSideMenu(false)}
        navigateTo={navigateTo}
        navigation={navigation}
      />

      <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
      <Alert
        open={alertControl.open}
        title={alertControl.title}
        message={alertControl.message}
        icon={alertControl.icon}
        onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
      />
    </SafeAreaView>
  )
};

export default Container;

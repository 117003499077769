import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  Card,
  Modal,
  FormRow,
  FormColumn,
  ButtonTransparent,
  Icon,
  TextSmall,
} from '../../components';
import { ScrollView, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

const ButtonsList = (props) => {
  const {
    dimensions,
    modalContentControl,
    setModalContentControl,
  } = props;

  return (
    <Modal
      open={modalContentControl.open}
      backdropPress={() => setModalContentControl({ ...modalContentControl, open: false })}
    >
      <ScrollView>
        <Card>
          <FormColumn alignItems='center' padding={5}>
            <FormRow justifyContent='flex-end'>
              <ButtonTransparent
                onPress={() => setModalContentControl({ ...modalContentControl, open: false })}
              >
                <Icon type="AntDesign" name="close" size={20} color={'black'} />
              </ButtonTransparent>
            </FormRow>
            <FormRow justifyContent='center' paddingBottom={20}>
              {modalContentControl.format === 'video' && renderVideo()}
              {modalContentControl.format === 'image' && renderImage()}
              {modalContentControl.format === 'embed' && renderEmbed()}
              {modalContentControl.format === 'text' && renderText()}
            </FormRow>
          </FormColumn>
        </Card>
      </ScrollView>
    </Modal>
  )

  function renderVideo() {
    return (
      <>
        {Platform.OS === 'web' ? (
          <video width={dimensions.width - 60} height={dimensions.height - 250} controls autoPlay>
            <source src={modalContentControl.url} />
          </video>
        ) : (
          <WebView
            source={{ uri: modalContentControl.url }}
            scrollEnabled={false}
          />
        )}
      </>
    )
  }

  function renderImage() {
    return (
      <Card
        backgroundImage={modalContentControl.url}
        resizeMode='contain'
      >
        <FormRow height={dimensions.height - 250} width={dimensions.width - 60} />
      </Card>
    )
  }

  function renderEmbed() {
    if (Platform.OS === 'web') {
      return (
        <iframe
          width={dimensions.width - 60}
          height={dimensions.height - 250}
          src={modalContentControl.url}
          frameborder="0"
          allow="camera; microphone; accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          webkitAllowFullScreen
          mozallowfullscreen
        ></iframe>
      )
    } else {
      return (
        <WebView
          source={{ uri: modalContentControl.url }}
          style={{ flex: 1 }}
          scrollEnabled={true}
        />
      )
    }

  }

  function renderText() {
    return (
      <TextSmall>
        {modalContentControl.url}
      </TextSmall>
    )
  }
};

export default ButtonsList;

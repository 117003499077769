import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';

export default ({
  children,
  id,
  onPress,
  width = null,
  background = "transparent",
  padding = 8,
  ...props
}) => {

  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const styles = StyleSheet.create({
    component: {
      alignItems: "center",
      backgroundColor: background,
      padding: padding,
      width: width,
      borderRadius: 5,
      opacity: isMouseOver ? 0.7 : 1,
    }
  });

  return (
    <TouchableOpacity
      key={id}
      style={
        styles.component
      }
      onPress={onPress}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {children}
    </TouchableOpacity>
  )
};

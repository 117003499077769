import React from 'react';
import { getDic } from '../../assets/i18n/dictionary';
import {
  FormRow,
  FormColumn,
  Button,
} from '../../components';

const ButtonsList = ({
  dimensions,
  navigatePage,
  modalCotent,
  openModalContent,
}) => {

  const width = dimensions.width > 610 ? 600 : dimensions.width - 10;

  return (
    <>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Baby'}
              onPress={() => openModalContent(modalCotent.a1)}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Junior'}
              onPress={() => navigatePage('MascotHouseJuniorPage', 'CASADOMASCOTEJUNIOR')}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      <FormRow justifyContent='center'>
        <FormColumn width={width}>
          <FormRow paddingTop={10}>
            <Button
              textColor='#1680ac'
              background='#fefffe'
              text={'Teen'}
              onPress={() => openModalContent(modalCotent.a2)}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
    </>
  )
};

export default ButtonsList;

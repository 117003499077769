import DeviceInfo from 'react-native-device-info';
import * as Network from 'expo-network';

export default {
  async isIos() {
    let _return = false;
    try {
      const _device = await DeviceInfo.getManufacturer();
      if (_device && _device.toUpperCase() === "APPLE" || _device.toUpperCase() === "IOS") {
        _return = true;
      }
    } catch (error) {
      console.log('error DeviceInfo', error);
    }
    return _return;
  },

  isTablet() {
    return DeviceInfo.isTablet();
  },

  isWeb() {
    let _deviceId = DeviceInfo.getDeviceId();
    if (_deviceId === 'unknown') {
      return true;
    } else {
      return false;
    }
  },

  async getDeviceInfo() {
    const _manufacturer = await DeviceInfo.getManufacturer();
    const _deviceName = await DeviceInfo.getDeviceName();
    const _ipAddress = await DeviceInfo.getIpAddress();
    const _macAddress = await DeviceInfo.getMacAddress();
    const _system = DeviceInfo.getSystemName();
    const _systemVersion = DeviceInfo.getSystemVersion();

    return {
      manufacturer: _manufacturer,
      deviceName: _deviceName,
      system: _system,
      systemVersion: _systemVersion,
      ip: _ipAddress,
      macAddress: _macAddress,
    };
  },

  async checkIfDeviceHasInternet() {
    try {
      let _connection = await Network.getNetworkStateAsync();
      if (_connection.isConnected) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error checkIfDeviceHasInternet');
      throw error;
    }
  }
}

import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';

export default ({
  children,
  id,
  text,
  onPress,
  width = null,
  maxWidth = maxWidth,
  background = "#456091",
  textColor = 'white',
  height = undefined,
  ...props
}) => {

  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const styles = StyleSheet.create({
    component: {
      alignItems: "center",
      backgroundColor: background,
      padding: 8,
      width: width,
      maxWidth: maxWidth,
      borderRadius: 5,
      flex: 1,
      height: height,
      opacity: isMouseOver ? 0.7 : 1,
    },
    text: {
      color: textColor,
      fontFamily: 'Verdana',
      fontSize: 16,
      textAlign: 'center',
    }
  });

  return (
    <TouchableOpacity
      key={id}
      style={
        styles.component
      }
      onPress={onPress}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      accessible={true}
      accessibilityLabel={text}
    >
      {children ? children : (<Text style={styles.text}>{text}</Text>)}
    </TouchableOpacity>
  )
};

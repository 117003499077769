import * as React from 'react';
import { store } from '../../store';
import * as actions from '../../store/actions';
import { getDic } from '../../assets/i18n/dictionary';
import {
  Container,
  Loading,
  Alert,
} from '../../components';
import { Dimensions } from 'react-native';

import ButtonsList from './ButtonsList';

const MascotHouseBabyPage = (props) => {

  const [dimensions, setDimensions] = React.useState(Dimensions.get("window"));
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingControl, setLoadingControl] = React.useState({
    open: true,
    message: getDic("carregando")
  });
  const [alertControl, setAlertControl] = React.useState({
    open: false,
    title: '',
    message: '',
    icon: 'info'
  });
  const [pathParams, setPathParams] = React.useState({
    errorMessage: '',
    eventId: '',
    guestId: '',
  });

  React.useEffect(() => {
    getPageData();
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window }) => {
        setDimensions(window);
      }
    );
    return () => subscription?.remove();
  }, [])

  const getPageData = React.useCallback(async () => {
    try {
      let _params = getRouteParams();
      if (_params.errorMessage) {
        setAlertControl({
          open: true,
          title: getDic("erro"),
          message: _params.errorMessage,
          icon: 'error',
        });
      }
      setPathParams(_params);
    } catch (error) {
      console.log('Error getPageData', error);
    } finally {
      setIsLoading(false);
      setLoadingControl({ ...loadingControl, open: false });
    }

    function getRouteParams() {
      try {
        let _params = props.route.params;

        let _return = {
          errorMessage: '',
          eventId: _params?.eventId || '',
          guestId: _params?.guestId || '',
        }

        if (!_return.eventId) {
          _return.errorMessage = 'Parâmetro "eventId" não declarado';
        }
        if (!_return.guestId) {
          _return.errorMessage = 'Parâmetro "guestId" não declarado';
        }

        return _return;
      } catch (error) {
        console.log('Error getRouteParams', error);
        throw error;
      }
    }
  });

  const navigatePage = React.useCallback(async (page = '') => {
    props.navigation.replace(page, { guestId: pathParams.guestId, eventId: pathParams.eventId });
  });

  return (
    <Container
      background='#1680ac'
      padding={0}
      scroll
    >
      <ButtonsList
        dimensions={dimensions}
        navigatePage={navigatePage}
      />

      {modalControl()}
    </Container >
  );

  function modalControl() {
    if (alertControl.open) {
      return (
        <Alert
          open={alertControl.open}
          title={alertControl.title}
          message={alertControl.message}
          icon={alertControl.icon}
          onPress={() => setAlertControl({ ...alertControl, open: false, icon: 'info' })}
        />
      )
    }
    if (loadingControl.open) {
      return <Loading open={loadingControl.open} loadingMessage={loadingControl.message} />
    }

    return null;
  }
};

export default MascotHouseBabyPage;

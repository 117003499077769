import * as React from 'react';
import Router from './Router';
import deviceService from './services/deviceService';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./store";
import { useKeepAwake } from 'expo-keep-awake';
import { Platform } from 'react-native';

const AppIndex = () => {

  useKeepAwake();

  if (Platform.OS === 'web') {
    document.documentElement.setAttribute("lang", 'pt-br');
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  );
};

export default AppIndex;

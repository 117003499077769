import React from 'react';
import { StyleSheet, Text } from 'react-native';

const TextSmall = ({
  children = '',
  color = "black",
  fontSize = 12,
  fontWeight,
  align,
  ...props
}) => {

  const styles = StyleSheet.create({
    text: {
      fontSize: fontSize,
      color: color,
      fontWeight: fontWeight,
      fontFamily: 'Verdana',
      textAlign: align
    }
  });

  return (
    <Text style={styles.text}>
      {children}
    </Text>
  )

};

export default TextSmall;

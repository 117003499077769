import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform } from 'react-native';

import DescompressionRooms from './pages/DescompressionRooms';
import MainStagePage from './pages/MainStagePage';
import MascotHouseBabyPage from './pages/MascotHouseBabyPage';
import MascotHouseJuniorPage from './pages/MascotHouseJuniorPage';
import MascotHousePage from './pages/MascotHousePage';
import MascotHouseTeenPage from './pages/MascotHouseTeenPage';
import Stage2BSAPage from './pages/Stage2BSAPage';
import Stage2BusinessAreaPage from './pages/Stage2BusinessAreaPage';
import Stage2KeyOfferingPage from './pages/Stage2KeyOfferingPage';
import Stage2Page from './pages/Stage2Page';
import Stage2SectorsPage from './pages/Stage2SectorsPage';
import StandCentersPage from './pages/StandCentersPage';
import StandDigitalTechnologyPage from './pages/StandDigitalTechnologyPage';
import StandDiversityAndInclusionPage from './pages/StandDiversityAndInclusionPage';
import StandEsgPage from './pages/StandEsgPage';
import StandFundationNttDataPage from './pages/StandFundationNttDataPage';
import StandPeopleAndStaffingPage from './pages/StandPeopleAndStaffingPage';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['http://localhost:19006'],
  config: {
    screens: {
      DescompressionRooms: 'Salas_de_Descompressao',
      MainStagePage: 'Palco_Principal',
      MascotHouseBabyPage: 'Casa_do_Mascote_Baby',
      MascotHouseJuniorPage: 'Casa_do_Mascote_Junior',
      MascotHousePage: 'Casa_do_Mascote',
      MascotHouseTeenPage: 'Casa_do_Mascote_Teen',
      Stage2BSAPage: 'Palco_2_BSA',
      Stage2BusinessAreaPage: 'Palco_2_Area_de_Negocios',
      Stage2KeyOfferingPage: 'Palco_2_Key_Offering',
      Stage2Page: 'Palco_2',
      Stage2SectorsPage: 'Palco_2_Setores',
      StandCentersPage: 'Stand_Centers',
      StandDigitalTechnologyPage: 'Stand_Digital_Technology',
      StandDiversityAndInclusionPage: 'Stand_Diversidade_e_Inclusao',
      StandEsgPage: 'Stand_ESG',
      StandFundationNttDataPage: 'Stand_Fundacao_NTT_Data',
      StandPeopleAndStaffingPage: 'Stand_People_e_Staffing',
    }
  },
};

const pageConfig = {
  animation: Platform.OS === 'ios' ? 'none' : 'default',
  headerShown: false,
  gestureEnabled: false,
  title: 'Digital Lovers',
}

const Router = () => {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="MainStagePage">
        <Stack.Screen
          name="DescompressionRooms"
          component={DescompressionRooms}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="MainStagePage"
          component={MainStagePage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="MascotHouseBabyPage"
          component={MascotHouseBabyPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="MascotHouseJuniorPage"
          component={MascotHouseJuniorPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="MascotHousePage"
          component={MascotHousePage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="MascotHouseTeenPage"
          component={MascotHouseTeenPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="Stage2BSAPage"
          component={Stage2BSAPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="Stage2BusinessAreaPage"
          component={Stage2BusinessAreaPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="Stage2KeyOfferingPage"
          component={Stage2KeyOfferingPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="Stage2Page"
          component={Stage2Page}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="Stage2SectorsPage"
          component={Stage2SectorsPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandCentersPage"
          component={StandCentersPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandDigitalTechnologyPage"
          component={StandDigitalTechnologyPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandDiversityAndInclusionPage"
          component={StandDiversityAndInclusionPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandEsgPage"
          component={StandEsgPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandFundationNttDataPage"
          component={StandFundationNttDataPage}
          options={{ ...pageConfig }}
        />
        <Stack.Screen
          name="StandPeopleAndStaffingPage"
          component={StandPeopleAndStaffingPage}
          options={{ ...pageConfig }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Router;

import axios from "axios";
import apiService from "./apiService";

export default {
  async setAdminPlusRankingPoints(guestId = '', eventId = '', rankingCode = '', rankingCodeSubtract = false) {
    try {
      const _options = {
        url: `${apiService.getUrl().gateway}api-admin-ranking/events/${eventId}/logins/${guestId}/earnedpointcreate`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          code: rankingCode,
          positive: rankingCodeSubtract ? false : true,
          log: 'digital_lovers_nnt_app',
        }
      };

      await axios(_options);

    } catch (error) {
      console.log('Error setAdminPlusRankingPoints', error);
      throw error;
    }
  }
}
